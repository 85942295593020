import React, { Fragment } from "react"
import Helmet from "react-helmet"

/* Import Global Hook(s) */
import { useWebsiteConfiguration } from "~queries/hooks"

const SEO = ({ title }) => {

  const { description, seoImage } = useWebsiteConfiguration()

  return (<Fragment>
    <Helmet>
      {/* General tags */}
      <title>{title == 'Untitled' || title == 'UNTITLED' || title == 'untitled' || !title ? 'Untitled Art Fairs' : title + ' | Untitled Art Fairs' }</title>
      <meta
        name="description"
        content={description}
      />
      <meta name="image" content={seoImage?.asset?.url} />
      {/* OpenGraph tags */}
      <meta property="og:url" content="https://untitledartfairs.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Untitled Art Fairs" />
      <meta
        property="og:description"
        content={description}
      />
      <meta property="og:image" content={seoImage?.asset?.url} />
    </Helmet>
  </Fragment>)
}

export default SEO
